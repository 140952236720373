import type React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { PHRASE_TYPE } from '../../entities/enum';
import Logo from '../logo/logo';
import SelectPhraseTheme from '../selectPhraseTheme/selectPhraseTheme';

interface HeaderProps {
	phraseType: PHRASE_TYPE;
	modalPhraseType: boolean;
	setModalPhraseType: Dispatch<SetStateAction<boolean>>;
}

const Header: React.FC<HeaderProps> = ({
	phraseType,
	modalPhraseType,
	setModalPhraseType,
}) => {
	return (
		<div className="flex absolute top-0 right-0 w-full p-4">
			<Logo />
			<SelectPhraseTheme
				modalPhraseType={modalPhraseType}
				setModalPhraseType={setModalPhraseType}
				phraseType={phraseType}
			/>
		</div>
	);
};

export default Header;
