import { PHRASE_TYPE } from '../../entities/enum';

interface Phrase {
	text: string;
	tag?: string;
}

export const phrasesByType: Record<string, Phrase[]> = {
	[PHRASE_TYPE.COMMEMORATIVE_DATES]: [
		{ text: 'Paz? Só com chocolate!', tag: '01_01' },
		{ text: 'Paz mundial? Começo aqui!', tag: '01_01' },
		{ text: 'Paz interior: missão impossível!', tag: '01_01' },
		{ text: 'Paz e amor... e pizza! ❤', tag: '01_01' },
		{ text: 'Vizinho, silêncio, por favor!', tag: '01_01' },
		{ text: 'Mundo em paz? Um sonho distante.', tag: '01_01' },
		{ text: 'Paz? Só se meu time ganhar!', tag: '01_01' },
		{ text: 'Paz mundial? Comece em casa!', tag: '01_01' },
		{ text: 'Mais paz, menos stress!', tag: '01_01' },
		{ text: 'Paz? Nem que a vaca tussa!', tag: '01_01' },
		{ text: 'Recarregando as baterias...', tag: '02_01' },
		{ text: 'Sociedade? Prefiro a minha companhia.', tag: '02_01' },
		{ text: 'Introvertido? Sim. Antissocial? Não, só seletivo.', tag: '02_01' },
		{ text: 'Minha festa favorita? A do silêncio.', tag: '02_01' },
		{ text: 'Carregando... 99% introvertido.', tag: '02_01' },
		{ text: 'Falar pouco, pensar muito.', tag: '02_01' },
		{ text: 'Introversão: a minha superpotência.', tag: '02_01' },
		{ text: 'O silêncio é a minha língua nativa. ', tag: '02_01' },
		{ text: 'Em casa é onde meu coração está. ❤', tag: '02_01' },
		{ text: 'Introvertido e orgulhoso! ', tag: '02_01' },
		{ text: 'Juiz de menores: herói de capa... jurídica! ♂⚖', tag: '03_01' },
		{ text: 'Fazendo a diferença, um menor de idade por vez. ⚖', tag: '03_01' },
		{ text: 'Justiça juvenil: a gente leva a sério! ⚖', tag: '03_01' },
		{ text: 'Juiz de menores: o Batman dos adolescentes. ⚖', tag: '03_01' },
		{ text: 'Menos problemas, mais sorrisos! ⚖', tag: '03_01' },
		{ text: 'Direito infantil: nosso superpoder! ⚖', tag: '03_01' },
		{
			text: 'Construindo um futuro melhor, um caso de cada vez. ⚖',
			tag: '03_01',
		},
		{ text: 'Juiz de menores: a voz dos que não têm voz. ⚖', tag: '03_01' },
		{ text: 'Justiça com um toque de carinho. ❤⚖', tag: '03_01' },
		{ text: 'Plantando sementes de justiça. ⚖', tag: '03_01' },
		{ text: 'Braille: Pontos que falam!', tag: '04_01' },
		{ text: 'Braille: A escrita com a alma.', tag: '04_01' },
		{ text: 'Tocar para ler: Sensacional!', tag: '04_01' },
		{ text: 'Braille: A visão dos dedos.', tag: '04_01' },
		{ text: 'Mais que pontos: Um universo.', tag: '04_01' },
		{ text: 'Braille: A leitura tátil.', tag: '04_01' },
		{ text: 'Braille: A mente que enxerga.', tag: '04_01' },
		{ text: 'Braille: A escrita sem limites.', tag: '04_01' },
		{ text: 'Braille: A magia dos pontos.', tag: '04_01' },
		{ text: 'Primeira tipografia? Que tipo?', tag: '05_01' },
		{ text: 'Adeus, pergaminhos! Olá, jornal! ', tag: '05_01' },
		{ text: 'Melchior, Baltasar e Gaspar', tag: '06_01' },
		{ text: 'Fé livre, espírito leve', tag: '07_01' },
		{ text: 'Respeito a todas as crenças', tag: '07_01' },
		{ text: 'Liberdade para cultuar', tag: '07_01' },
		{ text: 'Diversidade religiosa', tag: '07_01' },
		{ text: 'Um só céu, muitas fés', tag: '07_01' },
		{ text: 'Crença sem fronteiras', tag: '07_01' },
		{ text: 'Fé é um direito', tag: '07_01' },
		{ text: 'Religião em paz', tag: '07_01' },
		{ text: 'Celebrando a fé', tag: '07_01' },
		{ text: 'Respeito acima de tudo', tag: '07_01' },
	],
	[PHRASE_TYPE.BUGS_RESOLUTION]: [
		{ text: 'Bug? Deve ser feature nova' },
		{ text: 'Meu código tem vida própria' },
		{ text: 'Debugando o caos, linha por linha' },
		{ text: 'Bug encontrado. Caça ao rato' },
		{ text: 'Erro 404: Humano não encontrado' },
		{ text: 'Algoritmo rebelde' },
		{ text: 'O bug é a cereja do bolo... do inferno' },
		{ text: 'Bug? Só um mal-entendido com o PC' },
		{ text: 'Debugando a vida: uma linha de código por dia' },
		{ text: 'O bug te chama, mas você ignora' },
		{ text: 'Meu código tem vida... e bugs' },
		{ text: 'Debugging: a arte de encontrar o caos' },
		{ text: 'Erro 404: lógica não encontrada' },
		{ text: 'O bug é o mestre dos disfarces' },
		{ text: 'Meu código está de greve' },
		{ text: 'Debugando a realidade alternativa' },
		{ text: 'O bug é a cereja do bolo... do inferno' },
		{ text: 'Algoritmo rebelde: missão impossível' },
		{ text: 'Bug encontrado: missão abortar' },
		{ text: 'Meu código tem um alter ego malvado' },
		{ text: 'Quem com bug mexe, com bug se infecta' },
		{ text: 'Casa de bug, espeto de task' },
		{ text: 'Bug que dorme, a atualização leva' },
		{ text: 'De grão em grão, o bug se alastra' },
		{ text: 'Código dado não se olha o bug' },
		{ text: 'Quem busca bug, acha' },
		{ text: 'Não adianta chorar sobre o bug derramado' },
		{ text: 'Cada bug no seu sistema' },
		{ text: 'De grão em grão, o bug enche o programa.' },
		{ text: 'Mais vale um bug na mão do que dois voando' },
		{ text: 'De médico e de programador, todo mundo tem um pouco' },
		{ text: 'Bug que não aparece, não atrapalha' },
		{ text: 'Em terra de bugs, programador é rei' },
		{ text: 'Bug que não mata, fortalece o sistema' },
	],
	[PHRASE_TYPE.TARGET_DELIVER]: [
		{ text: 'Meta clara, sprint não para' },
		{ text: 'A cada sprint, mais perto da meta' },
		{ text: 'Rumo a meta' },
		{ text: 'Meta definida, sprint garantida' },
		{ text: 'Persistência é a chave da meta' },
		{ text: 'Coração na meta, alma na sprint' },
		{ text: 'Novas metas, novas conquistas' },
		{ text: 'Meta dada, sprint plena' },
		{ text: 'Meta dada, missão cumprida' },
		{ text: 'Sprint final, show de bola' },
		{ text: 'Meta é como flecha, certeira e precisa' },
		{ text: 'Sprint curta e intensa, como um raio' },
		{ text: 'Quem tem meta alcança' },
		{ text: 'Sprint final, vamos arrebentar' },
		{ text: 'Meta definida, foco total' },
		{ text: 'De sprint em sprint, a meta vem' },
		{ text: 'Audaciosa, mas realizável' },
		{ text: 'A cada sprint um novo aprendizado' },
		{ text: 'Sprint final, a vitória nos espera' },
		{ text: 'Sprint curto e poderoso, como um raio' },
		{ text: 'Sprint a frente, sem olhar para trás' },
		{ text: 'Meta na mira, foco total' },
		{ text: 'Meta é como um sonho, a ser realizado' },
		{ text: 'Sprint a sprint, construímos o sucesso' },
		{ text: 'Foguete da ré, mas a sprint não' },
		{ text: 'Foguete da ré, mas a meta não' },
		{ text: 'Foguete da ré, mas nós não' },
		{ text: 'Show de meta' },
		{ text: 'Ou bate, ou chora' },
		{ text: 'Jantando a meta' },
	],
	[PHRASE_TYPE.MOVIES]: [
		{ text: 'Avatar' },
		{ text: 'Vingadores: Ultimato' },
		{ text: 'Avatar: O Caminho da Água' },
		{ text: 'Titanic' },
		{ text: 'Star Wars: Episódio VII - O Despertar da Força' },
		{ text: 'Vingadores: Guerra Infinita' },
		{ text: 'Homem-Aranha: Sem Volta Para Casa' },
		{ text: 'Divertida Mente' },
		{ text: 'Jurassic World: O Mundo dos Dinossauros' },
		{ text: 'O Rei Leão' },
		{ text: 'Os Vingadores' },
		{ text: 'Velozes e Furiosos' },
		{ text: 'Top Gun: Maverick' },
		{ text: 'Frozen II' },
		{ text: 'Barbie' },
		{ text: 'Vingadores: Era de Ultron' },
		{ text: 'Frozen: Uma Aventura Congelante' },
		{ text: 'Super Mario Bros - O Filme' },
		{ text: 'Harry Potter e As Relíquias da Morte: Parte' },
		{ text: 'Pantera Negra' },
		{ text: 'Deadpool & Wolverine' },
		{ text: 'Star Wars: Episódio VIII - Os Últimos Jedi' },
		{ text: 'Jurassic World: Reino Ameaçado' },
		{ text: 'A Bela e A Fera' },
		{ text: 'Os Incríveis' },
		{ text: 'Velozes e Furiosos' },
		{ text: 'Homem de Ferro' },
		{ text: 'Minions' },
		{ text: 'O Senhor dos Anéis: O Retorno do Rei' },
		{ text: 'Capitão América: Guerra Civil' },
		{ text: 'Aquaman' },
		{ text: '007: Operação Skyfall' },
		{ text: 'Homem-Aranha: Longe de Casa' },
		{ text: 'Capitã Marvel' },
		{ text: 'Transformers: O Lado Oculto da Lua' },
		{ text: 'Parque dos Dinossauros' },
		{ text: 'Transformers: A Era da Extinção' },
		{ text: 'Batman - O Cavaleiro das Trevas Ressurge' },
		{ text: 'Coringa' },
		{ text: 'Star Wars: Episódio IX - A Ascensão Skywalker' },
		{ text: 'Toy Story' },
		{ text: 'Toy Story' },
		{ text: 'Piratas do Caribe: O Baú da Morte' },
		{ text: 'Rogue One: Uma História Star Wars' },
		{ text: 'Aladdin' },
		{ text: 'Piratas do Caribe: Navegando em Águas Misteriosas' },
		{ text: 'Star Wars: Episódio I - A Ameaça Fantasma' },
		{ text: 'Meu Malvado Favorito' },
		{ text: 'Procurando Dory' },
		{ text: 'Zootopia' },
		{ text: 'Alice no País das Maravilhas' },
		{ text: 'Harry Potter e A Pedra Filosofal' },
		{ text: 'O Hobbit: Uma Jornada Inesperada' },
		{ text: 'Batman - O Cavaleiro das Trevas' },
		{ text: 'Harry Potter e A Ordem da Fênix' },
		{ text: 'Jurassic World: Domínio' },
		{ text: 'Jumanji: Bem-vindo à Selva' },
		{ text: 'Oppenheimer' },
		{ text: 'Meu Malvado Favorito' },
		{ text: 'O Rei Leão' },
		{ text: 'Mogli: O Menino Lobo' },
		{ text: 'O Hobbit: A Batalha dos Cinco Exércitos' },
		{ text: 'Piratas do Caribe: No Fim do Mundo' },
		{ text: 'Meu Malvado Favorito' },
		{ text: 'Harry Potter e As Relíquias da Morte: Parte' },
		{ text: 'O Hobbit: A Desolação de Smaug' },
		{ text: 'Doutor Estranho no Multiverso da Loucura' },
		{ text: 'O Senhor dos Anéis: As Duas Torres' },
		{ text: 'Procurando Nemo' },
		{ text: 'Minions : A Origem de Gru' },
		{ text: 'Harry Potter e O Enigma do Príncipe' },
		{ text: 'Shrek' },
		{ text: 'Bohemian Rhapsody' },
		{ text: 'The Battle at Lake Changjin' },
		{ text: 'Harry Potter e O Cálice de Fogo' },
		{ text: 'Homem-Aranha' },
		{ text: 'Pets: A Vida Secreta dos Bichos' },
		{ text: 'A Era do Gelo' },
		{ text: 'O Senhor dos Anéis: A Sociedade do Anel' },
		{ text: 'Harry Potter e A Câmara Secreta' },
		{ text: 'Contra Spectre' },
		{ text: 'Homem-Aranha: De Volta ao Lar' },
		{ text: 'A Era do Gelo' },
		{ text: 'Batman vs Superman: A Origem da Justiça' },
		{ text: 'Wolf Warrior II' },
		{ text: 'Star Wars: Episódio III - A Vingança dos Sith' },
		{ text: 'Jogos Vorazes: Em Chamas' },
		{ text: 'Guardiões da Galáxia Vol' },
		{ text: 'Pantera Negra: Wakanda Para Sempre' },
		{ text: 'Divertida Mente' },
		{ text: 'Venom' },
		{ text: 'Thor: Ragnarok' },
		{ text: 'A Saga Crepúsculo: Amanhecer - Parte' },
		{ text: 'Guardiões da Galáxia Vol' },
		{ text: 'A Origem' },
		{ text: 'Transformers: A Vingança dos Derrotados' },
		{ text: 'Homem-Aranha' },
		{ text: 'Mulher-Maravilha' },
		{ text: 'Hi, Mom' },
		{ text: 'Independence Day' },
	],
	[PHRASE_TYPE.POPULAR_SAYINGS]: [
		{ text: 'A cavalo dado, não se olham os dentes' },
		{ text: 'A mentira tem perna curta' },
		{ text: 'À noite todos os gatos são pardos' },
		{ text: 'A ocasião faz o ladrão' },
		{ text: 'A pensar morreu um burro' },
		{ text: 'A pressa é inimiga da perfeição' },
		{ text: 'A união faz a força' },
		{ text: 'A voz do povo é voz de Deus' },
		{ text: 'Água mole em pedra dura, tanto bate até que fura' },
		{ text: 'Águas passadas não movem moinhos' },
		{ text: 'Amigos, amigos, negócios à parte' },
		{ text: 'Antes tarde do que nunca' },
		{ text: 'Apressado come cru' },
		{ text: 'Aqui se faz, aqui se paga' },
		{ text: 'Árvore que nasce torta, morre torta' },
		{ text: 'As aparências enganam' },
		{ text: 'Batatinha quando nasce, se esparrama pelo chão' },
		{ text: 'Cachorro que late não morde' },
		{ text: 'Cada cabeça, uma sentença' },
		{ text: 'Casa de ferreiro, espeto de pau' },
		{ text: 'De grão em grão a galinha enche o papo' },
		{ text: 'Desgraça pouco é bobagem' },
		{ text: 'Deus ajuda quem cedo madruga' },
		{ text: 'Deus escreve certo por linhas tortas' },
		{ text: 'Devagar com o andor que o santo é de barro' },
		{ text: 'Devagar e sempre' },
		{ text: 'Devagar se vai ao longe' },
		{ text: 'Dizei-me com quem andas e eu te direi quem és' },
		{ text: 'É melhor não cutucar a onça com vara curta' },
		{ text: 'É melhor prevenir do que remediar' },
		{ text: 'Em boca fechada não entra mosca' },
		{ text: 'Em rio que tem piranha, jacaré nada de costas' },
		{ text: 'Em terra de cego, quem tem um olho é rei' },
		{ text: 'Entre marido e mulher não se mete a colher' },
		{ text: 'Filho de peixe, peixinho é' },
		{ text: 'Gato escaldado tem medo de água fria' },
		{ text: 'Ladrão que rouba ladrão tem cem anos de perdão' },
		{ text: 'Macaco velho não pula em galho seco' },
		{ text: 'Mais vale um pássaro na mão do que dois voando' },
		{ text: 'Melhor prevenir que remediar' },
		{ text: 'Nada como um dia depois do outro' },
		{ text: 'Não adianta chorar sobre o leite derramado' },
		{ text: 'Não deixes para amanhã o que podes fazer hoje' },
		{ text: 'Não há bem que sempre dure, nem mal que nunca se acabe' },
		{ text: 'Não se faz uma omelete sem quebrar os ovos' },
		{ text: 'Nem tanto ao mar, nem tanto à terra' },
		{ text: 'Nem tudo que reluz é ouro' },
		{ text: 'Nem tudo são flores' },
		{ text: 'O barato sai caro' },
		{ text: 'O bom filho à casa torna' },
		{ text: 'O pior cego é o que não quer ver' },
		{ text: 'O prometido é devido' },
		{ text: 'O que não mata engorda' },
		{ text: 'O que os olhos não veem o coração não sente' },
		{ text: 'O seguro morreu de velho' },
		{ text: 'Olho por olho, dente por dente' },
		{ text: 'Onde há fumaça, há fogo' },
		{ text: 'Os últimos serão os primeiros' },
		{ text: 'Para baixo todo Santo ajuda' },
		{ text: 'Para bom entendedor, meia palavra basta' },
		{ text: 'Pela boca morre o peixe' },
		{ text: 'Pensando morreu um burro' },
		{ text: 'Quando a esmola é muita o santo desconfia' },
		{ text: 'Quando um burro fala, o outro baixa as orelhas' },
		{ text: 'Quando um não quer, dois não brigam' },
		{ text: 'Quanto mais alto, maior a queda' },
		{ text: 'Quem avisa amigo é' },
		{ text: 'Quem cala, consente' },
		{ text: 'Quem canta seus males espanta' },
		{ text: 'Quem casa quer casa' },
		{ text: 'Quem com ferro fere, com ferro será ferido' },
		{ text: 'Quem conta um conto aumenta um ponto' },
		{ text: 'Quem desdenha quer comprar' },
		{ text: 'Quem é vivo sempre aparece' },
		{ text: 'Quem espera sempre alcança' },
		{ text: 'Quem fala o que quer, escuta o que não quer' },
		{ text: 'Quem feio ama, bonito lhe parece' },
		{ text: 'Quem não arrisca não petisca' },
		{ text: 'Quem não chora não mama' },
		{ text: 'Quem não tem cão caça com gato' },
		{ text: 'Quem nunca comeu melado, quando come se lambuza' },
		{ text: 'Quem procura, acha' },
		{ text: 'Quem ri por último ri melhor' },
		{ text: 'Quem sai aos seus não degenera' },
		{ text: 'Quem semeia ventos, colhe tempestades' },
		{ text: 'Quem tem boca vai a Roma' },
		{ text: 'Quem tudo quer, tudo perde' },
		{ text: 'Quem vai ao vento, perde o assento' },
		{ text: 'Quem vê cara não vê coração' },
		{ text: 'Rapadura é doce, mas não é mole' },
		{ text: 'Saco vazio não fica de pé' },
		{ text: 'Santo da casa não faz milagre' },
		{ text: 'Se conselho fosse bom ninguém dava, vendia' },
		{ text: 'Se Maomé não vai à montanha, vai a montanha a Maomé' },
		{ text: 'Seguro morreu de velho' },
		{ text: 'Tudo tem solução, menos a morte' },
		{ text: 'Um gesto vale mais que mil palavras' },
		{ text: 'Um homem prevenido vale por dois' },
		{ text: 'Vão-se os anéis, ficam os dedos' },
		{ text: 'Vingança é um prato que se come frio' },
	],
	[PHRASE_TYPE.RANDOM]: [],
};
