import alvo from '../assets/images/alvo.png';
import chat from '../assets/images/chat.png';
import clapperboard from '../assets/images/clapperboard.png';
import ladybug from '../assets/images/ladybug.png';
import reiniciar from '../assets/images/reiniciar.png';
import { PHRASE_TYPE } from './enum';
import type { IconTextPhraseType } from './type';

export const TRANSLATED_PHRASE_TYPE = {
	[PHRASE_TYPE.COMMEMORATIVE_DATES]: 'Datas comemorativas',
	[PHRASE_TYPE.BUGS_RESOLUTION]: 'Resolução de bugs',
	[PHRASE_TYPE.TARGET_DELIVER]: 'Bater meta',
	[PHRASE_TYPE.RANDOM]: 'Aleatório',
	[PHRASE_TYPE.MOVIES]: 'Filmes',
	[PHRASE_TYPE.POPULAR_SAYINGS]: 'Ditados populares',
};

export const ICON_TEXT_BY_PHRASE_TYPE: Record<string, IconTextPhraseType> = {
	// [PHRASE_TYPE.COMMEMORATIVE_DATES]: {
	// 	text: TRANSLATED_PHRASE_TYPE[PHRASE_TYPE.COMMEMORATIVE_DATES],
	// 	icon: weekend,
	// },
	[PHRASE_TYPE.BUGS_RESOLUTION]: {
		text: TRANSLATED_PHRASE_TYPE[PHRASE_TYPE.BUGS_RESOLUTION],
		icon: ladybug,
	},
	[PHRASE_TYPE.TARGET_DELIVER]: {
		text: TRANSLATED_PHRASE_TYPE[PHRASE_TYPE.TARGET_DELIVER],
		icon: alvo,
	},
	[PHRASE_TYPE.MOVIES]: {
		text: TRANSLATED_PHRASE_TYPE[PHRASE_TYPE.MOVIES],
		icon: clapperboard,
	},
	[PHRASE_TYPE.POPULAR_SAYINGS]: {
		text: TRANSLATED_PHRASE_TYPE[PHRASE_TYPE.POPULAR_SAYINGS],
		icon: chat,
	},
	[PHRASE_TYPE.RANDOM]: {
		text: TRANSLATED_PHRASE_TYPE[PHRASE_TYPE.RANDOM],
		icon: reiniciar,
	},
};
