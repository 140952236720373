import type React from 'react';
import type { ReactNode } from 'react';

interface MainProps {
	children?: ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
	return (
		<div className="min-w-screen min-h-screen p-4 background-light flex align-items-center justify-content-center">
			{children}
		</div>
	);
};

export default Main;
