import { random } from 'lodash';
import { useCallback, useState } from 'react';
import { ICON_TEXT_BY_PHRASE_TYPE } from '../../entities/constant';
import { PHRASE_TYPE } from '../../entities/enum';
import { phrasesByType } from './phrases';

interface UsePhrase {
	phraseType: PHRASE_TYPE;
}

export const usePhrase = ({ phraseType }: UsePhrase) => {
	const [phrases, setPhrases] = useState<string[]>([]);
	const [selectedPhraseType, setSelectedPhraseType] = useState<PHRASE_TYPE>(
		PHRASE_TYPE.TARGET_DELIVER,
	);

	const getRandomPhraseType = useCallback(() => {
		const phraseTypeKeys = Object.keys(ICON_TEXT_BY_PHRASE_TYPE).filter(
			(key) => key !== PHRASE_TYPE.RANDOM,
		);
		return phraseTypeKeys[random(phraseTypeKeys.length - 1)] as PHRASE_TYPE;
	}, []);

	const getSelectedPhraseType = useCallback(
		() =>
			phraseType === PHRASE_TYPE.RANDOM ? getRandomPhraseType() : phraseType,
		[getRandomPhraseType, phraseType],
	);

	const searchPhrase = useCallback((type: PHRASE_TYPE) => {
		let phrasesFound = phrasesByType[type];

		if (type === PHRASE_TYPE.COMMEMORATIVE_DATES) {
			const day = String(new Date().getDate()).padStart(2, '0');
			const month = String(new Date().getMonth() + 1).padStart(2, '0');

			phrasesFound = phrasesFound.filter(
				(phraseFound) => phraseFound.tag === `${day}_${month}`,
			);
		}

		return phrasesFound[random(phrasesFound.length - 1)].text;
	}, []);

	const generatePhrase = useCallback(() => {
		const typeToGeneratePhrase = getSelectedPhraseType();
		setSelectedPhraseType(typeToGeneratePhrase);
		setPhrases((prev) => {
			return prev.concat(searchPhrase(typeToGeneratePhrase));
		});
	}, [getSelectedPhraseType, searchPhrase]);

	return {
		selectedPhraseType,
		phrases,
		generatePhrase,
	};
};
