export default function classesBuilder(props: Record<string, unknown>): string {
	const generatedClasses: string[] = [];

	for (const key of Object.keys(props)) {
		if (props[key]) {
			generatedClasses.push(key);
		}
	}

	return generatedClasses.join(' ');
}
