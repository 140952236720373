import type React from 'react';
import { useState } from 'react';
import CardPhraseTheme from './components/cardPhraseTheme/cardPhraseTheme';
import Header from './components/header/header';
import Main from './components/main/main';
import Modal from './components/modal/modal';
import Phrase from './components/phrase/phrase';
import { ICON_TEXT_BY_PHRASE_TYPE } from './entities/constant';
import { PHRASE_TYPE } from './entities/enum';

const App: React.FC = () => {
	const [modalPhraseType, setModalPhraseType] = useState(false);
	const [phraseType, setPhraseType] = useState(PHRASE_TYPE.RANDOM);

	return (
		<Main>
			<Modal
				title="Tema"
				description="Selecione o tema do nome da sprint"
				open={modalPhraseType}
				onClose={() => setModalPhraseType(false)}
			>
				<div className="grid">
					{Object.keys(ICON_TEXT_BY_PHRASE_TYPE).map((item) => (
						<div key={item} className="col-6 sm:col-4">
							<CardPhraseTheme
								phraseType={item as PHRASE_TYPE}
								onClick={() => {
									setPhraseType(item as PHRASE_TYPE);
									setModalPhraseType(false);
								}}
							/>
						</div>
					))}
				</div>
			</Modal>
			<Header
				modalPhraseType={modalPhraseType}
				setModalPhraseType={setModalPhraseType}
				phraseType={phraseType}
			/>
			<Phrase phraseType={phraseType} />
		</Main>
	);
};

export default App;
