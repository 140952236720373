import type React from 'react';
import { useMemo } from 'react';
import arrowDown from '../../assets/images/arrow-down.svg';
import close from '../../assets/images/close.svg';
import random from '../../assets/images/random.svg';

interface IconProps {
	name: string;
}

const Icon: React.FC<IconProps> = ({ name }) => {
	const imgSrc = useMemo(
		() =>
			({
				close,
				random,
				arrowDown,
			})[name],
		[name],
	);

	return <img src={imgSrc} alt="icon" />;
};

export default Icon;
