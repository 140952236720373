import type React from 'react';
import logo from '../../assets/images/logo.svg';

const Logo: React.FC = () => (
	<div className="flex flex-1 gap-2 align-items-center">
		<img className="logo" src={logo} alt="logo" />
		<div className="font-bold text-2xl hidden sm:block">sprintname</div>
	</div>
);

export default Logo;
