import type React from 'react';
import { useMemo } from 'react';
import { ICON_TEXT_BY_PHRASE_TYPE } from '../../entities/constant';
import type { PHRASE_TYPE } from '../../entities/enum';
import './cardPhraseTheme.css';

interface CardPhraseThemeProps {
	phraseType: PHRASE_TYPE;
	onClick: () => void;
}

const CardPhraseTheme: React.FC<CardPhraseThemeProps> = ({
	phraseType,
	onClick,
}) => {
	const { icon, text } = useMemo(
		() => ICON_TEXT_BY_PHRASE_TYPE[phraseType],
		[phraseType],
	);

	return (
		<button type="button" className="cardPhraseTheme" onClick={onClick}>
			<img src={icon} alt={text} width={32} />
			{text}
		</button>
	);
};

export default CardPhraseTheme;
