import type React from 'react';
import { useMemo } from 'react';
import { ICON_TEXT_BY_PHRASE_TYPE } from '../../entities/constant';
import type { PHRASE_TYPE } from '../../entities/enum';

interface BoxPhraseThemeProps {
	phraseType: PHRASE_TYPE;
}

const TagPhraseTheme: React.FC<BoxPhraseThemeProps> = ({ phraseType }) => {
	const { icon, text } = useMemo(
		() => ICON_TEXT_BY_PHRASE_TYPE[phraseType],
		[phraseType],
	);

	return (
		<div className="flex gap-3 align-items-center text-3xl">
			<img src={icon} alt={text} width={32} height={32} />
			{text}
		</div>
	);
};

export default TagPhraseTheme;
