import type React from 'react';
import { useMemo } from 'react';
import { ICON_TEXT_BY_PHRASE_TYPE } from '../../entities/constant';
import type { PHRASE_TYPE } from '../../entities/enum';

type PhraseThemeProps = {
	phraseType: PHRASE_TYPE;
};

const PhraseTheme: React.FC<PhraseThemeProps> = ({ phraseType }) => {
	const { icon, text } = useMemo(
		() => ICON_TEXT_BY_PHRASE_TYPE[phraseType],
		[phraseType],
	);

	return (
		<>
			<div className="flex align-items-center">
				<img src={icon} alt={text} width={32} />
			</div>
			<div className="text-left hidden sm:block">
				<div className="text-base">Tema</div>
				<div className="text-xl font-bold">{text}</div>
			</div>
		</>
	);
};

export default PhraseTheme;
