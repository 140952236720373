import type React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { PHRASE_TYPE } from '../../entities/enum';
import Icon from '../icon/icon';
import PhraseTheme from '../phraseTheme/phraseTheme';

interface SelectTextThemeProps {
	phraseType: PHRASE_TYPE;
	modalPhraseType: boolean;
	setModalPhraseType: Dispatch<SetStateAction<boolean>>;
}

const SelectPhraseTheme: React.FC<SelectTextThemeProps> = ({
	phraseType,
	modalPhraseType,
	setModalPhraseType,
}) => {
	return (
		<button
			type="button"
			className="border-round-lg background-clear py-2 px-3 shadow-default flex align-items-center gap-3 border-none cursor-pointer"
			onClick={() => setModalPhraseType(true)}
		>
			<PhraseTheme phraseType={phraseType} />
			<div className="flex align-items-center pr-1">
				<Icon name="arrowDown" />
			</div>
		</button>
	);
};

export default SelectPhraseTheme;
