import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { FiCopy } from 'react-icons/fi';
import { GrPowerReset } from 'react-icons/gr';
import { PHRASE_TYPE } from '../../entities/enum';
import classesBuilder from '../../utils/classesBuilder/classesBuilder';
import Button from '../button/button';
import TagPhraseTheme from '../tagPhraseTheme/tagPhraseTheme';
import { usePhrase } from './usePhrase';

interface PhraseProps {
	phraseType: PHRASE_TYPE;
}

const Phrase: React.FC<PhraseProps> = ({ phraseType }) => {
	const { phrases, selectedPhraseType, generatePhrase } = usePhrase({
		phraseType,
	});

	const [isCopied, setCopied] = useState(false);

	const copyToClipboard = useCallback((str: string) => {
		const el = document.createElement('textarea');
		el.value = str;
		el.setAttribute('readonly', '');
		el.style.position = 'absolute';
		el.style.left = '-9999px';
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
		setCopied(true);
	}, []);

	useEffect(() => {
		generatePhrase();
	}, [generatePhrase]);

	return (
		<div className="flex flex-column align-items-center align-content-center py-8">
			{phraseType === PHRASE_TYPE.RANDOM && (
				<div className="pb-3 sm:pb-0">
					<TagPhraseTheme phraseType={selectedPhraseType} />
				</div>
			)}
			{selectedPhraseType === PHRASE_TYPE.COMMEMORATIVE_DATES && (
				<div className="text-base text-3xl fadein pt-3">
					Hoje é 02 de janeiro, <b>dia do introvertido</b>, e o nome da sprint
					será
				</div>
			)}
			{phrases.map((phrase, index) => (
				<div
					key={`${phrase}_${index}`}
					className={classesBuilder({
						'font-bold': true,
						'text-6xl': true,
						'md:text-8xl': true,
						'text-center': true,
						'animation-fill-forwards': true,
						fadeinup: index === phrases.length - 1,
						fadeoutdown: index !== phrases.length - 1,
						absolute: index !== phrases.length - 1,
						'pointer-events-none': index !== phrases.length - 1,
						hidden: phrases.length - index > 2,
					})}
				>
					{phrase}
				</div>
			))}

			<div className="grid fixed md:static left-0 bottom-0 w-full md:w-auto p-4 m-0">
				<div className="col-6">
					<Button
						className={classesBuilder({
							'w-full': true,
							'background-clear': true,
							'bg-blue-500': !isCopied,
							'bg-green-600': isCopied,
						})}
						onClick={() => copyToClipboard(phrases[phrases.length - 1])}
					>
						<div className="w-1rem flex align-items-center">
							{isCopied ? <BsCheckLg /> : <FiCopy />}
						</div>
						{isCopied ? 'Copiado' : 'Copiar'}
					</Button>
				</div>
				<div className="col-6">
					<Button
						className="background-primary w-full"
						onClick={() => {
							generatePhrase();
							setCopied(false);
						}}
					>
						<div className="w-1rem flex align-items-center">
							<GrPowerReset />
						</div>
						Trocar
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Phrase;
