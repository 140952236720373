import type React from 'react';
import close from '../../assets/images/close.svg';

interface ModalProps {
	title?: string;
	description?: string;
	children: React.ReactNode;
	onClose?: () => void;
	open?: boolean;
}

const Modal: React.FC<ModalProps> = ({
	children,
	title,
	description,
	open,
	onClose,
}) => {
	return open ? (
		<>
			<div className="min-w-screen min-h-screen fixed top-0 left-0 background-dark z-3 opacity-50 fadein" />
			<div className="min-w-screen min-h-screen fixed top-0 left-0 z-4 p-3 flex align-items-center justify-content-center flip">
				<div className="background-clear border-round-lg p-3 flex flex-column gap-3">
					<div className="flex">
						<div className="w-full">
							{title && <div className="text-2xl font-bold">{title}</div>}
							{description && <div className="text-base">{description}</div>}
						</div>

						<div>
							<button
								type="button"
								className="border-none background-clear border-1 border-900 border-round-lg p-2 cursor-pointer"
								onClick={onClose}
							>
								<img src={close} alt="close" />
							</button>
						</div>
					</div>
					{children}
				</div>
			</div>
		</>
	) : null;
};

export default Modal;
