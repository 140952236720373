import type React from 'react';
import './button.css';

interface ButtonProps {
	children: React.ReactNode;
	onClick: () => void;
	className?: string;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, className }) => {
	return (
		<button
			type="button"
			className={['button', className].join(' ')}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default Button;
